<template>
  <HomepageHero :settings="hero_settings" />

  <AnchorNavigation :anchors="pageAnchors" v-if="pageAnchors?.length" />

  <DynamicContentResolver
    v-if="Array.isArray(dynamic_content) && dynamic_content.length"
    :blocks="(dynamic_content as DynamicContentBlocksFragment[])"
  />
</template>

<script lang="ts" setup>
import type { DynamicContentBlocksFragment } from '#gql'
import { getResponsiveImage } from '~/lib/helpers'

const { locale } = useI18n()
useGqlHeaders({ 'Content-Language': locale.value })

const { data } = await useAsyncGql('GetPageable', { slug: '/' })
const { title, hero_settings, dynamic_content } = data.value?.pageableBySlug?.model || {} as any
const heroImage = hero_settings?.image?.[0]

const pageAnchors = computed( () => dynamic_content?.reduce((acc, block) => {
  if (!!block.anchor) acc.push(block.anchor)
  return acc
}, []) )

useHead({
  title,
  link: heroImage ? [
    { rel: 'preload', href: getResponsiveImage(heroImage, '3840'), as: 'image'}
  ] : undefined
})
</script>