<template>
  <section
    class="hp-hero"
    role="banner"
    v-intersect
  >
    <div class="hp-hero__bg">
      <video v-if="video" :src="video" :poster="image || undefined" autoplay loop playsinline disablepictureinpicture muted />
    </div>
    <div class="hp-hero__content">
      <h1>
        <span class="hp-hero__h-line-1" data-animation="slide-fade-in" v-html="heading[0]" />
        <span class="hp-hero__h-line-2" data-animation="slide-fade-in" data-animation-delay="200" v-html="heading[1]" />
      </h1>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { HeroSettingsFragment, ExtrasFragment } from '#gql'

type ExtraHomepageImage = Extract<ExtrasFragment, { __typename: 'ExtraHomepageImage' }>

const props = defineProps<{
  settings?: HeroSettingsFragment
}>()

const video = ref('')

onMounted(() => {
  const desktopVideo = props.settings?.video?.[0]?.original_url || ''
  const mobileVideo = props.settings?.video_mobile?.[0]?.original_url || ''
  video.value = window.innerWidth >= 768
    ? desktopVideo
    : (mobileVideo || desktopVideo)
})

const image = computed(() => props.settings?.image?.[0]?.responsive_images?.find(it => it?.size === '3840')?.url)

const heading = computed(() => 
  (props.settings?.hero_title || '')
    .replaceAll('[', '<span>')
    .replaceAll(']', '</span>')
    .split('|') || []
)

// const images = computed(() => {
//   const hpExtra = props.settings?.extras?.find(it => it?.__typename === 'ExtraHomepageImage') as ExtraHomepageImage
//   return hpExtra?.items?.map(group => group?.images?.[0]) || []
// })
</script>

<style lang="scss">
@keyframes scroll-line-pulse {
  from { height: 80% }
  to { height: 60% }
}

.hp-hero {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  position: relative;

  &__bg {
    @include absolute-cover;
    pointer-events: none;
    user-select: none;

    // [data-animated] & {
    //   opacity: 1;
    //   transition: opacity 600ms ease 200ms;
    // }

    img, video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      @include absolute-cover;
      background: #000;
      opacity: 0.45;
    }
  }


  &__content {
    @include page-content;
    padding-bottom: _rem(100px);

    h1 {
      font-size: _rem(80px);
      color: var(--color-gold);
      text-transform: uppercase;
      max-width: _rem(500px);
      display: flex;
      flex-direction: column;

      @include breakpoint(medium down) {
        font-size: _rem(64px);
      }
      @include breakpoint(small down) {
        font-size: _rem(48px);
      }

      span {
        display: inline-block;
        z-index: 1;
      }
    }

    @media (max-height: 780px) and (min-aspect-ratio: 1) {
      padding-bottom: 14vh;

      h1 {
        font-size: min(10vh, 13vw);
      }
    }
  }

  &__h-line-2 {
    @include body-font;
    font-weight: 400;
    color: var(--color-white);
    display: block;
  }
}
</style>